.layout2__container {
  height: 100vh;
}

.layout2__sider {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0;
  min-width: 252px !important;
  max-width: 252px !important;
  width: 252px !important;
  height: 100vh;
  .ant-layout2-sider-children {
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.sider__logo__container {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.sider__links__container {
  padding: 0px 27px;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.sider__link,
.sider__sub__link {
  width: 100%;
  max-width: 100%;
  white-space: normal;
  text-align: left;
  font-weight: 500;
  font-size: @text-sm;
  display: flex;
  height: max-content;
  padding: 0;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &:hover {
    color: white !important;
    .sider__icon__container--client {
      svg,
      g,
      path {
        opacity: 1 !important;
        fill: #4faa89 !important;
      }
    }
  }
}

.sider__sub__link--active {
  color: white !important;
  font-weight: 500px;
}

.sider__sub__link {
  margin-left: 2rem;
  width: 90%;
}

.sider__icon__container {
  width: 16px;
  height: 16px;
  margin-top: 1px;
  margin-right: 1rem;

  .sider__link__icon {
    margin-right: 1rem;
  }
}
.content__container {
  padding: 0px;
  overflow-y: scroll;
}

.content__container {
  padding: 0px;
  overflow-y: scroll;
}

.layout2__header {
  padding: 0;
  position: fixed;
  width: 100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 17.5rem;
  z-index: 1;
  box-shadow: 0.5px 0.866px 2px 0px @primary-white;
}

.layout2__breadcrumbs {
  font-size: @text-xs;
  font-weight: 500;
  margin: 104px 24px 8px;
}

.header__notif__icon__container {
  display: flex;
}

.header__divider {
  width: 1px;
  height: 50%;
  background: @secondary-text;
  opacity: 0.3;
  margin: 0 1.5rem 0 1.8rem;
}

.profile__menu__toggle {
  display: flex;
  cursor: pointer;

  height: 80%;
  width: auto;
  align-items: center;

  .profile__menu__text__container {
    display: flex;
    flex-direction: column;
    margin-right: 1.5rem;
    margin-left: 0.7rem;

    .profile__menu__user__name {
      line-height: 17.1px;
      font-size: @text-sm;
      font-weight: 700;
    }
    .profile__menu__user__role {
      line-height: 14.6px;
      font-size: @text-xs;
      font-weight: 500;
    }
  }

  .profile__menu__icon__container {
    height: min-content;
    line-height: 1;
  }
}

@hack: true; @import "./src/styles/theme.less";
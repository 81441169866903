.layout__container {
  height: 100vh;
  display: grid;
  grid-template-rows: 72px 1fr;
}

/* .layout__container {
  height: 100vh;
  overflow: hidden;
} */

.layout__header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  width: 100%;
}

.layout__background-wrapper {
  display: flex;
  align-items: center;
  background-image: url("../../assets/images/login-full.png");
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@hack: true; @import "./src/styles/theme.less";
.auth__forgot__password {
  font-size: @text-sm;
  font-family: @secondary-font;
  padding-right: 0;
  font-weight: 600;
}

.resend-link-container {
  margin-bottom: 30px;
}

.timer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.timer-view {
  display: flex;
  flex-direction: row;
}

.timer-minute {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94px;
  height: 56px;
  border: @border-primary;
}
.timer-seconds {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94px;
  height: 56px;
  border: @border-primary;
  border-left: none;
}

.forgot__pwd_email {
  margin-top: 20px;
  margin-bottom: 70px;
}

@hack: true; @import "./src/styles/theme.less";
.app__row {
  width: 404px;
  height: 72px;
  background: #fafafa;
  border-radius: 16px;
  margin-bottom: 16px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  cursor: pointer;
  &.selected {
    background: #f3f5ff;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
    &:hover {
      cursor: not-allowed;
    }
  }
  .app__initials {
    width: 40px;
    height: 40px;
    background: #2f49d1;
    border-radius: 9.58974px;

    color: white;
    font-weight: 700;
    font-size: 19.1795px;
    line-height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .app__name {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* Primary Black */
    color: #0b2540;
    margin-bottom: 8px;
  }
  .app__desc {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #0b2540;
    opacity: 0.5;
  }
}

@hack: true; @import "./src/styles/theme.less";
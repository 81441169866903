@media (min-width: 720px) and (max-width: 1920px) {
  .apps__container {
    height: 70vh;
    padding: 31px 53px !important;
    .app__list-container {
      height: 55%;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

@media (min-width: 1920px) and (max-width: 2560px) {
  //insert styles here...
  .apps__container {
    height: fit-content;

    .app__list-container {
      height: fit-content;
    }
  }
}

.apps__container {
  width: 509px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 56px 53px;
  margin-left: auto;
  margin-right: auto;
  .apps__main__heading {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #0b2540;
    margin-bottom: 5px;
  }
  .apps_description {
    font-family: "HK Grotesk";
    font-weight: 400;
    font-size: 16px;
    color: #435465;
    line-height: 21px;
  }
  .app__list-container {
    margin-top: 40px;
    margin-bottom: 26px;
  }
}

@hack: true; @import "./src/styles/theme.less";
.company__row {
  width: 98%;
  height: 72px;
  background: @primary-white;
  border-radius: 16px;
  margin-bottom: 24px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  cursor: pointer;
  &.selected {
    background: #f3f5ff;
  }
  .company-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 59px;
      height: 59px;
      border-radius: 5px;
    }
  }
  .app__name {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* Primary Black */
    color: @primary-black;
    margin-bottom: 8px;
  }
  .app__desc {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: @primary-black;
    opacity: 0.5;
  }
  .country__continent {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* Primary Black */
    color: @primary-black;
    white-space: pre;
  }
  .country__info-container {
    width: 75%;
  }
}

@hack: true; @import "./src/styles/theme.less";
@media (min-width: 720px) and (max-width: 1919px) {
  .companies__container {
    height: 70vh !important;
    padding: 31px 53px !important;
    .company__list-container {
      height: 44% !important;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

@media (min-width: 1920px) and (max-width: 2559px) {
  //insert styles here...
  .companies__container {
    height: fit-content;
    .company__list-container {
      height: 55% !important;
    }
  }
}

.companies__container {
  width: 759px;
  height: 646px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 56px 53px;
  margin-left: auto;
  margin-right: auto;
  .spinner {
    display: block;
  }
  .apps__main__heading {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: @primary-black;
    margin-bottom: 11px;
  }
  .apps_description {
    font-family: @secondary-font;
    font-weight: 400;
    font-size: 16px;
    color: @primary-black;
    line-height: 21px;
  }
  .companies__searchbar {
    box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.06), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 2px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .company__list-container {
    margin-bottom: 44px;
    overflow-y: auto;
    height: 55%;
  }
}

@hack: true; @import "./src/styles/theme.less";
label {
  font-weight: 500;
  margin-bottom: 8px;
  display: inline-block;
  color: @primary-black !important;
}

button {
  font-weight: 600 !important;
}
.spinner {
  font-size: 20px;
}

.width-100 {
  width: 100%;
}

.spinner-md {
  font-size: 22px !important;
}

/* --- Horizontal Alignment --- */
.align-center {
  display: flex;
  justify-content: center;
}

.align-end {
  display: flex;
  justify-content: flex-end;
}

.align-start {
  display: flex;
  justify-content: flex-start;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-center-vertically {
  display: flex;
  align-items: center;
}

/* --- Headings --- */
.main-heading {
  font-size: @text-lg;
  font-weight: 600;
  color: @primary-black !important;
}

.sub-heading {
  font-weight: 600;
  color: @primary-black !important;
  font-size: @text-md;
}

/* --- Text Width --- */
.text-wrap {
  width: 100%;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* --- Margins --- */
.mt-32 {
  margin-top: 32px;
}
.mb-32 {
  margin-bottom: 32px;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mt-24 {
  margin-top: 24px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mt-16 {
  margin-top: 16px;
}

/* --- Paddings --- */
.pb-16 {
  padding-bottom: 16px;
}
.pt-16 {
  padding-top: 16px;
}

.pl-24 {
  padding-left: 24px;
}

.pr-24 {
  padding-right: 24px;
}
